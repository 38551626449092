import React from "react";
import Page from "../components/Page";
import "../styles/privacypolicy.scss"

export default function PrivacyPolicy() {
  return (
    <>
      <Page title={"Privacy Policy - Blomstertjänst"}>
        <div className="container px-5 py-6 mx-auto privacypolicy max-w-screen-xl">
          <br />
          <h1 id="-privacy-policy-" className="text-2xl text-gray-900 font-bold md:text-4xl">Privacy Policy</h1>
          <br />
          <hr/>
          <h3 id="samtycke">Samtycke</h3>
          <p>Genom att använda vår webbplats samtycker du härmed till vår integritetspolicy och godkänner dess villkor.</p>
          <h3 id="-information-som-vi-samlar-in-"><>Information som vi samlar in</></h3>
          <p>Den personliga information som du ombeds att lämna, och skälen till varför du ombeds att tillhandahålla den, kommer att göras tydliga för dig när vi ber dig att lämna din personliga information. </p>
          <p>Om du kontaktar oss direkt kan vi få ytterligare information om dig såsom ditt namn, e-postadress, telefonnummer, innehållet i meddelandet och/eller bilagor du kan skicka till oss och all annan information du kan välja att tillhandahålla.</p>
          <h3 id="-hur-vi-anv-nder-informationen-"><>Hur vi använder informationen</></h3>
          <p>Vi använder informationen vi samlar in på olika sätt, inklusive för att:</p>
          <ul>
            <li>Tillhandahålla, driva och underhålla vår webbplats</li>
            <li>Förbättra, anpassa och utöka vår webbplats</li>
            <li>Förstå och analysera hur du använder vår webbplats</li>
            <li>Kommunicera med dig, antingen direkt eller genom någon av våra partners, inklusive för kundtjänst, för att förse dig med uppdateringar och annan information som rör webbplatsen och för marknadsförings- och reklamsyfte</li>
            <li>Hitta och förhindra bedrägerier</li>
          </ul>
          <h3 id="kakor-och-web-beacons-">Kakor <>och Web Beacons</></h3>
          <p>Precis som alla andra webbplatser använder Blomstertjänst &quot;kakor&quot;. Dessa cookies används för att lagra information inklusive besökarnas preferenser och de sidor på webbplatsen som besökaren besökte eller besökte.</p>
          <p>Informationen används för att optimera användarnas upplevelse genom att anpassa innehållet på vår webbsida baserat på besökarnas webbläsartyp och/eller annan information.</p>
          <h3 id="analysverktyg-fr-n-tredje-part">Analysverktyg från tredje part</h3>
          <p>Vi tar hjälp av tredjepartsverktyg för analys av våra besökares beteende. Denna analys är vanligtvis anonym och kan inte spåras tillbaka till dig. Statistiken använder vi för att förbättra och optimera vår webbplats.</p>
          <p>På vår hemsida använder vi oss av Google Analytics från Google LLC (1600 Amphitheater Parkway, Mountain View, CA 94043, USA; “<>Google</>“). Är din vanliga vistelseort i europeiska ekonomiska samarbetsområdet eller Schweiz ansvarar Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) för behandlingen av dina uppgifter och efterlevnaden av gällande dataskyddslagar. </p>
          <p>Google Analytics använder informationen om besökares användande av sidan i syfte att bedöma och sammanställa rapporter för analys. Informationen lagras och hanteras av Google, som kan komma att överföra denna information till tredje part vid lagkrav eller där sådan part bearbetar informationen åt Google.</p>
          <h3 id="tredje-parts-sekretesspolicyer">Tredje parts sekretesspolicyer</h3>
          <p>Blomstertjänst sekretesspolicy gäller inte för andra annonsörer eller webbplatser. Därför råder vi dig att konsultera respektive integritetspolicy för dessa tredjepartsannonsservrar för mer detaljerad information.</p>
          <p>Det kan innehålla deras praxis och instruktioner om hur man väljer bort vissa alternativ. Du kan välja att inaktivera cookies genom dina individuella webbläsaralternativ. För att få mer detaljerad information om cookiehantering med specifika webbläsare finns den på webbläsarnas respektive webbplatser.</p>
          <h3 id="google-adsense-och-annonser">Google Adsense och annonser</h3>
              <p>Google AdSense använder vad som kallas en DoubleClick Cookie. En DoubleClick-cookie är en cookie som placeras på en användares enhet när användaren klickar på en annons på en partners webbplats.</p>
              <ul>
                <li>Tredjepartsleverantörer, inklusive Google, använder cookies för att visa annonser baserat på en användares tidigare besök på webbplatsen.</li>
                <li>Googles användning av DoubleClick-cookien gör det möjligt för Google och dess partners att visa annonser för dig baserat på dina besök på vår webbplats och/eller andra webbplatser på internet.</li>
                <li>Du kan välja bort att visa  intressebaserad reklam från en tredjepartsleverantör. Men information om detta hittar du på aboutads.info</li>
              </ul>
          <h3 id="-ccpa-sekretessr-ttigheter-s-lj-inte-min-personliga-information-"><>CCPA-sekretessrättigheter (sälj inte min personliga information)</></h3>
          <p>Enligt CCPA, bland andra rättigheter, har konsumenter i Kalifornien rätt att: Begära att ett företag som samlar in en konsuments personuppgifter avslöjar de kategorier och specifika delar av personuppgifter som ett företag har samlat in om konsumenter. Begär att ett företag raderar eventuella personuppgifter om konsumenten som ett företag har samlat in. Begär att ett företag som säljer en konsuments personuppgifter inte säljer konsumentens personuppgifter. Om du gör en begäran har vi en månad på oss att svara dig. Om du vill utöva någon av dessa rättigheter, vänligen kontakta oss.</p>
          <h3 id="-gdpr-dataskyddsr-ttigheter-"><>GDPR Dataskyddsrättigheter</></h3>
          <p>Vi vill se till att du är fullt medveten om alla dina dataskyddsrättigheter. Varje användare har rätt till följande:</p>
          <ul>
            <li>Du har rätt att begära kopior av dina personuppgifter.</li>
            <li>Du har rätt att begära att vi korrigerar all information som du anser är felaktig. Du har också rätt att begära att vi kompletterar den information du anser är ofullständig.</li>
            <li>Du har rätt att begära att vi raderar dina personuppgifter, under vissa förutsättningar.</li>
            <li>Du har rätt att begära att vi begränsar behandlingen av dina personuppgifter, under vissa förutsättningar.</li>
            <li>Du har rätt att invända mot vår behandling av dina personuppgifter, under vissa förutsättningar.</li>
            <li>Du har rätt att begära att vi överför de uppgifter som vi har samlat in till en annan organisation, eller direkt till dig, under vissa förutsättningar. Om du gör en begäran har vi en månad på oss att svara dig. Om du vill utöva någon av dessa rättigheter, vänligen kontakta oss.</li>
          </ul>
          <br />
        </div>
      </Page>
    </>
  );
}
